<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.product"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0 mm_custom_input_padding" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" >
              <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item :label="$t('message.quantity')" prop="incoming_quantity" class="label_mini">
                      <el-input 
                          :min="0" 
                          type="number" 
                          size="mini" 
                          v-model="form.incoming_quantity"
                      ></el-input>
                    </el-form-item>
                </el-col>
                <!-- end-col -->
                <el-col :span="8">
                    <el-form-item :label="columns.weight.title" prop="weight" class="label_mini">
                        <el-input
                          :min="0"
                          type="number"
                          size="mini"
                          :disabled="form.measuring_type_id && form.measuring_type_id !== 1"
                          @input="updatePoundAndKG(form, 'weight_in_kg')"
                          v-model="form.weight"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <!-- end-col -->

                <el-col :span="8">
                    <el-form-item :label="columns.weight_in_pounds.title" prop="weight_in_pounds" class="label_mini">
                        <el-input 
                            v-show="!(form.measuring_type_id === 2)"
                            :min="0" 
                            type="number" 
                            size="mini" 
                            @input="updatePoundAndKG(form, 'weight_in_pounds')"
                            v-model="form.weight_in_pounds"
                        ></el-input>
                    </el-form-item>
                </el-col>
              </el-row>
              
              <el-row :gutter="20">
                <el-col :span="9">
                    <el-form-item :label="columns.measuring_type.title" prop="measuring_type" class="label_mini">
                        <select-measuring-type
                          :size="'mini'"
                          :placeholder="columns.measuring_type.title"
                          :id="form.measuring_type_id"
                          v-model="form.measuring_type_id"
                          @input="updateWeight(form)"
                        ></select-measuring-type>
                    </el-form-item>
                </el-col>
                <!-- end-col -->
                <el-col :span="5">
                    <el-form-item :label="columns.width.title" prop="name" class="label_mini">
                        <el-input
                          :min="0"
                          type="number"
                          size="mini"
                          :disabled="!form.measuring_type_id || form.measuring_type_id === 1"
                          @input="updateWeight(form)"
                          v-model="form.width"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <!-- end-col -->
                <el-col :span="5">
                    <el-form-item :label="columns.height.title" prop="name" class="label_mini">
                        <el-input
                          :min="0"
                          type="number"
                          size="mini"
                          :disabled="!form.measuring_type_id || form.measuring_type_id === 1"
                          @input="updateWeight(form)"
                          v-model="form.height"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <!-- end-col -->
                <el-col :span="5">
                    <el-form-item :label="columns.length.title" prop="name" class="label_mini">
                        <el-input
                          :min="0"
                          type="number"
                          size="mini"
                          :disabled="!form.measuring_type_id || form.measuring_type_id === 1"
                          @input="updateWeight(form)"
                          v-model="form.length"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <!-- end-col -->
              </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectMeasuringType from "@/components/inventory/select-measuring-type";

export default {
    mixins: [form, drawer, show],
    name: "productCirculation",
    components: {
        selectMeasuringType,
    },
    data() {
        return {
          rules: {
            incoming_quantity: [
                { required: true, message: this.$t('message.please_select_input', {input: this.$t('message.quantity')}), trigger: 'change' },
            ],
            weight: [
                { required: true, message: this.$t('message.please_select_input', {input: this.$t('message.weight')}), trigger: 'change' },
            ],
          } 
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            model: "productCirculations/model",
            columns: "dealProducts/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "productCirculations/update",
            show: "productCirculations/show",
        }),

        updatePoundAndKG(row, type){
            if(type === 'weight_in_kg'){
                this.$set(this.form, 'weight_in_pounds',  _.round(parseFloat(row.weight) * this.$kg_to_pound, 3));
            }else{
                this.$set(this.form, 'weight',  _.round(parseFloat(row.weight_in_pounds) / this.$kg_to_pound, 3));
            }
        },
        updateWeight(row){
            if(row.measuring_type_id && row.measuring_type_id === 2){
                let size_weight = (parseFloat(row.width) * parseFloat(row.length) * parseFloat(row.height)) / 6000;
                this.$set(this.form, 'weight', _.round(parseFloat(size_weight), 3));
            }else{
                this.$set(this.form, 'weight', JSON.parse(JSON.stringify(this.model.weight)));
            }
        },

        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            if(res.data.result.success){
                              this.$alert(res);
                              this.parent().listChanged();
                            }else{
                              this.$warning_message(res);
                            }
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
